import React, { useState, useMemo } from 'react';
import { allCountryCodes } from './countryCodes.js';

const UserFormTable = ({ onFormSubmit, isLoading, design }) => {
  const [formData, setFormData] = useState({
    phoneNumber: '',
    countryCode: '+1', // Default country code
    name: ''
  });
  const [isMobile, setIsMobile] = useState(false);
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const countryCodes = allCountryCodes

  const handleCountryCodeChange = (e) => {
    setFormData({ ...formData, countryCode: e.target.value });
  };
  const handleCountryCodeInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, countryCode: value });

    // Filter the country codes based on input
    if (value) {
      const filtered = countryCodes.filter((country) =>
        country.name.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredCountryCodes(filtered);
    } else {
      setFilteredCountryCodes([]);
    }
  };

  const handleCountryCodeSelect = (code) => {
    setFormData({ ...formData, countryCode: code });
    setFilteredCountryCodes([]); // Clear the suggestions after selection
  };
  

  const [errors, setErrors] = useState({});

  const validatePhoneNumber = (phoneNumber) => {
    const digitPattern = /^\d+$/; // Pattern to check if all characters are digits
    const validLengthPattern = /^\d{10,15}$/; // Pattern to check if the number is between 10 and 15 digits

    if (phoneNumber.length < 10) {
      return { isValid: false, message: 'Phone number is too short. It must be at least 10 digits.' };
    }
    
    if (!digitPattern.test(phoneNumber)) {
      return { isValid: false, message: 'Phone number should only contain digits' };
    }

    

    if (!validLengthPattern.test(phoneNumber)) {
      return { isValid: false, message: 'Phone number must be between 10 and 15 digits.' };
    }

    return { isValid: true, message: '' }; // Return valid if both conditions pass
  };


  const validateForm = () => {
    const newErrors = {};

    // Check if name is empty
    if (!formData.name.trim()) {
      newErrors.name = 'Name cannot be empty';
    }

    // Check if phone number is valid
    const phoneValidation = validatePhoneNumber(formData.phoneNumber);
    if (!phoneValidation.isValid) {
      newErrors.phoneNumber = phoneValidation.message;
    }
    const validCountryCode = countryCodes.some(
      (country) => country.code === formData.countryCode
    );
    if (!validCountryCode) {
      newErrors.countryCode = 'Invalid country code';
    }

    setErrors(newErrors);

    // If there are no errors, the form is valid
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    setErrors({});
    onFormSubmit(formData);

  };

  const buttonStyle = useMemo(() => {
    switch (design) {
      case 'kate':
        return {
          backgroundColor: '#4ABA75',
          color: '#FFFFFF',
          hoverBg: '#3D9D62',
          padding: '0.75rem 1.5rem',
          fontSize: '1.125rem',
          height: 'auto',
          minHeight: '3rem',
          borderRadius: '9999px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem'
        };
      case 'orange':
        return {
          backgroundColor: '#eb9637',
          color: '#FFFFFF',
          hoverBg: '#d88730',
          padding: '0.5rem 1rem',
          fontSize: '1rem',
          height: 'auto',
          minHeight: '2.5rem',
          borderRadius: '0.375rem'
        };
      case 'cloobeck':
        return {
          backgroundColor: '#1430AD',
          color: '#FFFFFF',
          hoverBg: '#1430AD',
          padding: '0.5rem 1rem',
          fontSize: '1rem',
          height: 'auto',
          minHeight: '2.5rem',
          borderRadius: '0.375rem'
        };
      default:
        return {
          backgroundColor: '#4B0082', // Changed to purple-900
          color: '#FFFFFF',
          hoverBg: '#3B0062', // Slightly lighter purple for hover
          padding: '0.5rem 1rem',
          fontSize: '1rem',
          height: 'auto',
          minHeight: '2.5rem',
          borderRadius: '0.375rem'
        };
    }
  }, [design]);

  const buttonText = design === 'kate' ? 'Talk to Kate' : 'Talk Now';


  const LoadingSpinner = useMemo(() => () => (
    <svg className="animate-spin h-5 w-5 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  ), []);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center w-full">
      <div className="mb-4 w-full max-w-lg">
        <div className="flex w-full">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Enter your name"
            className="py-2 px-4 border rounded-lg w-full"
          />
        </div>
        {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
      </div>

      <div className="mb-4 w-full max-w-lg">
        <div className="flex w-full relative">
          <input
            type="text"
            name="countryCode"
            value={formData.countryCode}
            onChange={handleCountryCodeInputChange}
            placeholder="Enter country code or country"
            className="py-2 px-4 border rounded-l-lg bg-gray-100 w-1/4"
          />

          {filteredCountryCodes.length > 0 && (
            <ul className="absolute top-full left-0 w-1/4 bg-white border mt-1 z-10 max-h-40 overflow-y-auto">
              {filteredCountryCodes.map((country) => (
                <li
                  key={country.code}
                  className="cursor-pointer py-2 px-4 hover:bg-gray-100"
                  onClick={() => handleCountryCodeSelect(country.code)}
                >
                  {country.name} ({country.code})
                </li>
              ))}
            </ul>
          )}

          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            placeholder="Enter your phone"
            className="py-2 px-4 border rounded-r-lg w-3/4"
          />
        </div>
        {errors.countryCode && <p className="text-red-500 text-sm mt-1">{errors.countryCode}</p>}
        {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>}
      </div>

      <button
        className={`w-full max-w-lg mx-auto
                ${isMobile ? `active:bg-${buttonStyle.hoverBg}` : `hover:bg-${buttonStyle.hoverBg}`}
                flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 text-sm sm:text-base`}
        style={{
          backgroundColor: buttonStyle.backgroundColor,
          color: buttonStyle.color,
          padding: buttonStyle.padding,
          fontSize: buttonStyle.fontSize,
          height: buttonStyle.height,
          minHeight: buttonStyle.minHeight,
          borderRadius: buttonStyle.borderRadius,
          display: buttonStyle.display,
          alignItems: buttonStyle.alignItems,
          justifyContent: 'center',
          gap: buttonStyle.gap
        }}
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <LoadingSpinner />
            <span className="ml-2">Connecting...</span>
          </>
        ) : (
          <span>{buttonText}</span>
        )}
      </button>
    </form>
  );
};

export default UserFormTable;