import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import VIPCallCard from "./VIPCallCard";


const VIPPage = () => {
    const { vipId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [vipConfig, setVipConfig] = useState(() => {
        const savedData = sessionStorage.getItem("VIPConfig");
        try {
            return savedData ? JSON.parse(savedData) : null; // Attempt to parse the saved data
        } catch (error) {
            console.error("Error parsing VIPConfig from sessionStorage:", error);
            return null; // Return null if parsing fails
        }
    });
    const [label, setLabel] = useState('');
    const [token, setToken] = useState(null);
    const location = useLocation();

    useEffect(()=>{
        sessionStorage.removeItem("VIPConfig");

    }, [location.search])

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const tokenParam = searchParams.get('token');

        if (tokenParam && tokenParam !== token) {
            setToken(tokenParam);
            sessionStorage.setItem('token', tokenParam);
        }
        else if (!tokenParam) {
            setToken(null);
            sessionStorage.removeItem('token');
        }
    }, [location.search, token]);

    const fetchVIPDataByName = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_vip_config_by_name/${vipId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (Array.isArray(data) && data[1] === 404) {
                throw new Error(data[0].message);
            }

            setVipConfig(data);
            
            return data;
        } catch (error) {
            console.error('Error fetching VIP data by name:', error);
            throw error;
        }
    };

    const fetchVIPDataByNameAndToken = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/get_vip_config_by_name_and_token/${vipId}/${sessionStorage.getItem('token')}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (Array.isArray(data) && data[1] === 404) {
                throw new Error(data[0].message);
            }
            setVipConfig(data);
            
            return data;
        } catch (error) {
            console.error('Error fetching VIP data by name and token:', error);
            throw error;
        }
    };

    const fetchVIPData = useCallback(async () => {
        console.log('Fetching Data ...');
        setIsLoading(true);
        const tokenParam = sessionStorage.getItem('token')
        
        if (!vipConfig) {
            try {
                if (tokenParam) {
                    await fetchVIPDataByNameAndToken();
                } else {
                    await fetchVIPDataByName();
                }
                sessionStorage.setItem("VIPConfig", JSON.stringify(vipConfig))
            } catch (error) {
                console.error('Error in fetching');
                setVipConfig(null);
            } finally {
                setIsLoading(false);
            }
        }
        else{
           setIsLoading(false)
        }
    }, [vipId, token, location.search]);

    useEffect(() => {
        if (vipId) {
            fetchVIPData();
        }
    }, [fetchVIPData, vipId]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const labelParam = searchParams.get('label');
        if (labelParam) {
            setLabel(labelParam);
        }
    }, [location.search]);


    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/customcall",
            title: "Call Custom AI Dental Assistant"
        });
    }, []);

    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = 'https://fast.wistia.com/embed/medias/twrn63czfj.jsonp';
        script1.async = true;
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        script2.async = true;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    useEffect(() => {
        if (window._wq) {
            window._wq = window._wq || [];
            window._wq.push({
                id: 'mh313ag1y4',
                options: {
                    playerColor: "#7128d7",
                    stillUrl: "https://fast.wistia.com/embed/medias/twrn63czfj/swatch"
                },
                onReady: (video) => {
                    video.bind('play', () => {
                        ReactGA.event({
                            category: 'Video',
                            action: 'Play',
                            label: 'AI Teammates for Dentists'
                        });
                    });

                    video.bind('pause', () => {
                        ReactGA.event({
                            category: 'Video',
                            action: 'Pause',
                            label: 'AI Teammates for Dentists'
                        });
                    });

                    video.bind('end', () => {
                        ReactGA.event({
                            category: 'Video',
                            action: 'Ended',
                            label: 'AI Teammates for Dentists'
                        });
                    });

                    video.bind('percentwatchedchanged', (percent) => {
                        if (percent >= 25 && !video.reached25) {
                            video.reached25 = true;
                            ReactGA.event({
                                category: 'Video',
                                action: 'Progress 25%',
                                label: 'AI Teammates for Dentists'
                            });
                        }
                        if (percent >= 50 && !video.reached50) {
                            video.reached50 = true;
                            ReactGA.event({
                                category: 'Video',
                                action: 'Progress 50%',
                                label: 'AI Teammates for Dentists'
                            });
                        }
                        if (percent >= 75 && !video.reached75) {
                            video.reached75 = true;
                            ReactGA.event({
                                category: 'Video',
                                action: 'Progress 75%',
                                label: 'AI Teammates for Dentists'
                            });
                        }
                    });
                }
            });
        }
    }, []);

    const LoadingContent = ({ text }) => (
        <div className="fixed inset-0 flex items-center justify-center bg-[#7128d7] bg-opacity-5">
            <div className="text-center">
                <h1 className="text-xl sm:text-3xl md:text-4xl font-bold text-[#7128d7] mb-8">
                    {text}
                </h1>
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-[#7128d7] mx-auto"></div>
            </div>
        </div>
    );

    const MainContent = () => (
        <div className="flex min-h-screen w-full md:w-1/2 mx-auto bg-opacity-5 text-center">
            <div className="flex-grow flex flex-col justify-center items-center p-4">
                <div className="w-full max-w-4xl mx-auto">
                    {vipConfig ? (
                        <VIPCallCard
                            vipId={vipId}
                            vipConfig={vipConfig}
                            label={label}
                            token={sessionStorage.getItem('token')}
                        />
                    ) : (
                        <LoadingContent text="No Such User" />
                    )}
                </div>
            </div>
        </div>
    );

    return isLoading ? <LoadingContent text="Loading your AI Receptionist..." /> : <MainContent />;
};

export default VIPPage;